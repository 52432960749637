.inputRow {
  display: inline-block;
  position: relative;
}

.inputRow__advancedSearchInput {
  width: 40%;
  height: 55px;
  border: none;
  margin: 10px;
  border-radius: 2px;
  background-color: white;
  border: 1px solid #c1c8d6;
  box-shadow: inset 0.5px 0.5px 0 #aaaaaa;
  padding: 0.5rem;
  padding-top: 1rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  box-sizing: border-box;
  outline: none;
}

.inputRow__advancedSearchInput:focus {
  border: 3px solid #6389eb;
}

.inputRow__label {
  position: absolute;
  padding-top: 1.3em;
  left: 1.5em;
  transition: right 0.2s;
  margin: auto;
  font-size: 12px;
  color: #e87f1c;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
}
