.historySearchForm {
    display: flex;
    flex-direction: column;
    text-align: start;
    background-color: #DCE3F6;
}

.historySearchForm__buttonContainer {
    display: flex;
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
    justify-content: space-evenly;
    display: inline-block;
    position: relative;
    margin-left: 10px;
}

.historySearchForm__buttonContainer button {
    width: 40%;
}