.table {
  border-collapse: collapse;
  border-radius: 5px 5px 0 0;
  border-top: 0;
  margin: 20px 0;
  font-size: 0.9em;
  table-layout: fixed;
  width: 100%;
}

.table__accordion {
  margin: 0;
  box-shadow: none;
}

.table__data {
  padding: 8px 12px;
  word-wrap: break-word;
}
.centered__table__data {
  text-align: center;
}
.leftAlign__table__data {
  text-align: start;
}

.table__data {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.4rem 1rem 0.4rem 0.4rem !important; /*To override bootstrap styling*/
  border-top: 0 !important;
}

.table__data__concept {
  word-wrap: break-word;
  white-space: normal;
  padding: 0.5rem 1rem 0.5rem 0.5rem !important; /*To override bootstrap styling*/
}

.table__data__concept:nth-of-type(odd) {
  font-weight: 500;
}

.table__data__star {
  float: right;
}

.table__link {
  color: #313b53;
  text-decoration: none;
}

.table__link:hover {
  color: #313b53;
  text-decoration: none;
}

.table__data__about {
  font-weight: 500;
  width: 30%;
}

tbody {
  border: 1px solid #c2c9db;
}
