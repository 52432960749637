.errorMessageErrorStyle{
    background-color: #395b9c;
    top: 60%;
    left: 30%;
    position: absolute;
    height: 20%;
    width: 40%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Montserrat", "Helvetica Neue", "Helvetica", sans-serif;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.411);
    flex-direction: column;
    text-align: center;
    z-index: 2;
}

.errorMessageButtonAccept{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    margin-bottom: 0;
    padding: 0.75rem 1rem 0.7rem;
    background-color: #36a232;
    border: 1px solid #308f2c;
    border-radius: 2px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.06);
    text-decoration: none;
    font-family: "Montserrat", "Helvetica Neue", "Helvetica", sans-serif;
    font-size: 1rem;
    line-height: 1;
    white-space: nowrap;
    color: white;
    cursor: pointer;
}

.errorMessageModalOverlay {
    display: flex;
    position: absolute;    
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width:100%;
    height:220%;
    background-color:rgba(255,255,255,0.5)
      
}