.upperInputFields {
    display: flex;
    flex-direction: column;
}

.depotInputField {
    display: flex;
    flex-direction: column;
}

.archiveArchiveHolderClearButton{
    display: flex;
    padding-left: 19%;
}