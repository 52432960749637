.inputRow {
  display: inline-block;
  position: relative;
}

.dropdownInput {
  width: 40%;
  height: 55px;
  margin: 10px;
  padding-left: 0.4rem;
  padding-top: 0.8rem;
  border-radius: 2px;
  background-color: white;
  color: #6389eb;
  border: 1px solid #c1c8d6;
  box-shadow: inset 0.5px 0.5px 0 #aaaaaa;
  box-sizing: border-box;
  background-image: url(/src/components/atoms/inputs/baseDropdownInput/caret-mask.svg),
    linear-gradient(#6389eb, #6389eb);
  background-repeat: no-repeat;
  background-size: 14px 8px;
  background-position: 96%;
  appearance: none;
  outline: none;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.dropdownInput:focus {
  box-shadow: 0 0 0 3px #6389eb;
}

.dropdownInput::-ms-expand,
.dropdownInput::-webkit-inner-spin-button,
.dropdownInput::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

.dropdownInput__label {
  position: absolute;
  top: 1.3em;
  left: 1.5em;
  transition: right 0.2s;
  margin: auto;
  font-size: 12px;
  color: #e87f1c;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
}
