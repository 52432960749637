.table__header {
  text-align: left;
  background-color: #ecf0fa;
}

.table__header__xlarge {
  width: 80%;
}

.table__header__large {
  width: 20%;
}

.table__header__small {
  width: 10%;
}

.table__header__xsmall {
  width: 5%;
}

.table__header__xxsmall {
  width: 3%;
}

.table__header__concept {
  width: 30%;
}

.table__header__concept-large {
  width: 70%;
}

.table__header {
  padding: 8px 12px;
  word-wrap: break-word;
  font-size: 0.75rem;
}
