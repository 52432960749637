.historySearchPage {
    display: flex;
    flex-direction: column;
    background-color: #ECF0FA;
    padding: 1.75rem;
}

.historySearchPage__Form--Open {
    display: block;
}

.historySearchPage__Form--Close {
    display: none;
}