.activeAddSearchPhraseButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 35px;
  border-radius: 5px;
  border: 1px solid transparent;
  background-color: rgba(255, 255, 255, 0);
  color: #1b6ec2;
  font-size: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
}

.inactiveAddSearchPhraseButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 35px;
  border-radius: 5px;
  border: 1px solid transparent;
  background-color: rgba(255, 255, 255, 0);
  color: #8d8d8d;
  font-size: 20px;
}

.activeAddSearchPhraseButton:hover {
  cursor: pointer;
}

.add-search-phrase-icon {
  color: #919fbe;
}
