.inputFields {
    display: flex;
    flex-direction: column;
}

.multiSearchContainer{
    display: flex;
    flex-direction: column;
}
.andSeparator{
    padding-left: 20%;
}
.inputFieldsTimeRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.serieVolumeClearButton{
    display: flex;
    padding-left: 19%;
}