.searchPhraseButton {
  display: flex;
  background-color: #6389eb;
  border: 1px solid #4671e0;
  text-align: left;
  height: 86%;
  margin: 3px;
  border-radius: 2px;
  padding-left: 8px;
  padding-bottom: 4px;
  max-width: 94%;
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.searchPhraseRemove {
  border: none;
  background-color: #6389eb;
  color: rgba(240, 240, 240, 0.808);
  padding: 6px 8px;
}

.searchPhraseRemove:hover {
  cursor: pointer;
}

.searchPhraseText {
  color: white;
  /* width: 97%; */
  font-weight: 600;
}

.search-phrase-button-icon {
  width: 10px;
}
