.searchButton {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 400;
  color: #212529;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* border: 1px solid #4671e0; */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 5.4em;
  height: 100%;
  background-color: #6389eb;
  color: #fff;
  cursor: pointer;
  margin-left: 2px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.searchButtonInactive {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  border: 1px solid #4671e0;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 2px;
  display: block;
  width: 100%;
  background-color: #bdbebe;
  color: rgb(22, 22, 22);
  cursor: not-allowed;
  opacity: 50%;
}

@media (min-width: 1024px) {
  .button_simple_search {
    display: block;
    margin: 0 auto;
    width: 20em;
  }
}

button:disabled,
button[disabled] {
  border: 1px solid #c1c8d6;
  background-color: #c1c8d6;
  cursor: not-allowed;
}
