.freeSearchPage {
    display: flex;
    flex-direction: column;
    background-color: #ECF0FA;
    padding: 1.75rem;
}

.freeSearchPage__magnifyingGlass {
    height: 8em;
    margin-top: 15%;
    color: #DCE3F6;
}