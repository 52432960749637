.volumeSearchPage {
    display: flex;
    flex-direction: column;
    background-color: #ECF0FA;
    padding: 1.75rem;
}

.volumeSearchPage__Form--Open {
    display: block;
}

.volumeSearchPage__Form--Close {
    display: none;
}