.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown__content {
    display: block;
    position: absolute;
    background-color: white;
    min-width: 317px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border: 1px solid rgba(194,201,219, 1);
    text-align: left;

  }

  .dropdown__content__inactive{
    display: none;
  }

  
  .dropdown__content a {
    padding: 8px 12px;
    text-decoration: none;
    display: block;
    opacity: 1;
    color: rgba(34,34,34,1);
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
  }
  
  .dropdown a:hover {
    opacity: 1;
    background-color: rgba(236,240,250, 1);
  }