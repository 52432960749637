.icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  /* top: -0.15em; */
  /* margin-right: 0.5rem; */
  fill: currentColor;
}
.icon--medium {
  width: 16px;
  height: 16px;
}
.icon--large {
  width: 20px;
  height: 20px;
}
.icon--xlarge {
  width: 24px;
  height: 24px;
}
.icon--solo {
  margin-right: 0;
}
