.activePageIndicator {
    background-color: #dddddd !important; 
  }
  
  .paginationHolderStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  
  .pagination ul > li {
    display: inline;
  }
  
  .pagination ul > li > a,
  .pagination ul > li > span {
    float: left;
    padding: 4px 12px;
    line-height: 22px;
    text-decoration: none;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-left-width: 0;
  }
  
  .pagination ul > li > a:hover,
  .pagination ul > li > a:focus,
  .pagination ul > .active > a,
  .pagination ul > .active > span {
    background-color: #f5f5f5;
  }
  
  .pagination ul > .active > a,
  .pagination ul > .active > span {
    color: #858585;
    cursor: default;
  }
  
  .pagination ul > .disabled > span,
  .pagination ul > .disabled > a,
  .pagination ul > .disabled > a:hover,
  .pagination ul > .disabled > a:focus {
    color: #858585;
    background-color: transparent;
    cursor: default;
  }
  
  .pagination ul > li:first-child > a,
  .pagination ul > li:first-child > span {
    border-left-width: 1px;
    -webkit-border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    border-top-left-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -webkit-background-clip: padding-box;
    -moz-border-radius-bottomleft: 4px;
    -moz-background-clip: padding;
    border-bottom-left-radius: 4px;
    background-clip: padding-box;
  }
  
  .pagination ul > li:last-child > a,
  .pagination ul > li:last-child > span {
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    border-top-right-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    -webkit-background-clip: padding-box;
    -moz-border-radius-bottomright: 4px;
    -moz-background-clip: padding;
    border-bottom-right-radius: 4px;
    background-clip: padding-box;
  }
  
  .pagination_centered {
    text-align: center;
    cursor: pointer;
  }
  