.informationModalButtonStyle {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  display: inline-block;
  background-color: #ffffff;
  border: 1px solid #c1c8d6;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  text-align: left;
  font-size: 0.875rem;
  font-weight: bold;
  color: #6389eb;
  line-height: 1.375rem;
  min-height: 2.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  cursor: pointer;
  margin: 10px 20px 10px 10px;
}

.informationModalButtonStyle:hover {
  background-color: #dcdfe9;
}
