.table__row__header {
  color: black;
  font-weight: bold;
  font-size: 1.1em;
  position: sticky;
  top: 78px;
}

.table__row {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  font-weight: 500;
  cursor: pointer;
  height: 50px;
  border: solid rgba(128, 128, 128, 0.274) 1px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  border-left: 4px solid #c2c9db;
}

.table__symbol {
  width: 4em;
  color: #6389eb;
  text-align: center;
}

.table__row:hover {
  background: #eaf3fc !important;
}

.table__row__default-cursor {
  cursor: default;
}
