.advancedSearchButton {
  color: #6389eb;
  background-color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font-weight: 700;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  height: 100%;
  border-bottom: 4px solid #fff;
}

/* .dropbtn:hover, .dropbtn:focus {
    background-color: #358ec9;
  } */

.advancedSearchButton__selected {
  color: #6389eb;
  border-bottom: 4px solid #6389eb;
  background-color: white;
  padding: 16px;
  font-size: 16px;
  border-left: none;
  border-right: none;
  border-top: none;
  cursor: pointer;
  font-weight: 700;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  height: 100%;
}
