.multiWord {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.multiWord__InputField {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: white;
  border: 1px solid #c2c9db;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.addButton {
  width: 35px;
  border-radius: 5px;
  border: 1px solid transparent;
  background-color: #1b6ec2;
  color: white;
  font-size: 20px;
}
.addButton:hover {
  cursor: pointer;
  background-color: #175ca1;
}
