.freeTextSearchButton {
  color: #6389eb;
  background-color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font-weight: 700;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  border-top-left-radius: 7px !important;
  border-bottom: 4px solid #fff;
}

/* .btn:hover, .btn:focus {
    background-color: #2980B9;
  } */

.freeTextSearchButton__selected {
  color: #6389eb;
  border-bottom: 4px solid #6389eb;
  background-color: white;
  padding: 16px;
  font-size: 16px;
  border-left: none;
  border-right: none;
  border-top: none;
  cursor: pointer;
  font-weight: 700;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  border-top-left-radius: 7px !important;
}
