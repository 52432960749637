.headerButton {
    height: 3em;
    width: 100%;
    margin: 10px;
    background-color: #DCE3F6;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 700;
    border-radius: 3px;
    font-size: 20px;
    text-align: left;
    border: none;
    cursor: pointer;
    margin: 0;
}

.headerButton__Container{
   display: flex;
   align-items: center;
   flex-direction: row;
   height: 3em;
   justify-content: space-between;
   padding-left: 5px;
   padding-right: 2em;

}

.headerButton__TextContainer {
    display: flex;
    flex-direction: column;
    color: #6389EB;
    font-size: 15px;
}

