.searchForm {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 97%;
  background-color: #dce3f6;
  align-items: center;
  padding: 1.2em 1.2em 0.6em 1.2em;
}

.searchForm__upperContainer {
  display: grid;
  grid-template-columns: 2.2fr 1fr 1fr;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: fit-content;
}

.searchForm__Input {
  height: 100%;
}

.searchForm__buttomContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 2em;
  margin-top: 0.3em;
}

.searchForm__SearchButton {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  width: 10em;
  margin-left: 8px;
}

.searchForm__InformationButton {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: end;
}

.searchForm__wordCount {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  color: #919fbe;
  padding-right: 2em;
}
