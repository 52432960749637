.informationModalDisplayStyles {
  background-color: #ecf0fa;
  top: 50%;
  left: 50%;
  transform: translate(-50%) translateY(-50%);
  position: absolute;
  height: 388px;
  width: 600px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  color: #222222;
  /* box-shadow: 0px 10px 10px 5px rgba(0, 0, 0, 0.411); */
  flex-direction: column;
  text-align: start;
  z-index: 2;
  opacity: 1;
}

.informationModalDisplayStyles p {
  padding: 5px;
}

.informationModalH1 {
  width: 100%;
  display: flex;
  justify-content: left;
  padding: 20px;
  align-items: center;
  flex-direction: row;
  color: #222222;
  background-color: #fdfefe;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  border-bottom: 1px solid #c1c8d6;
  white-space: pre-wrap;
  font-size: 20px;
}

.informationModalTitle {
  flex-grow: 1;
  margin-left: 8px;
}

.informationModalText {
  padding-left: 20px;
  padding-left: 20px;
  flex-grow: 1;
}

.informationModalButtonPosition {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  flex-direction: row;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.07);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  border-top: 1px solid #c1c8d6;
}

.informationModalOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(10, 10, 10, 0.397);
  z-index: 1;
}
