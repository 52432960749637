.navigationBar{
    display: flex ;
    border-bottom: 1px solid rgba(194,201,219, 1);
    
    background-color: white !important;
    border-top-left-radius: 7px !important;
    min-height: 5.5rem;

    position: sticky;
    top: 0px;
    z-index: 1;

    width: 100%;

}

