.inputFields {
    display: flex;
    flex-direction: column;
}

.multiSearchContainer {
    display: flex;
    flex-direction: column;
}

.andSeparator {
    padding-left: 20%;
}

.historyClearButton {
    display: flex;
    padding-left: 19%;
}

.infoText {
    font-size: 12px;
    color: rgb(112, 110, 110);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}