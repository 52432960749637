.multiWordInputRow {
  display: flex;
  position: relative;
  flex-grow: 1;
}

.multiWordInputRow__SearchInput {
  height: 30px;
  width: 90%;
  border: none;
  padding-left: 0.6em;
  height: 33px;
  border-radius: 2px;
  background-color: white;
  outline: none;
}

.multiWordInputRow_label {
  position: absolute;
  padding-top: 1.3em;
  left: 1.5em;
  transition: right 0.2s;
  margin: auto;
  font-size: 12px;
  color: #e87f1c;
  font-weight: 700;
}
