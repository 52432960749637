* {
  margin: 0;
  box-sizing: border-box;
}
#root {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  /* padding-top: 5em; */
}
body {
  /* background-color: #ecf0fa; */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
