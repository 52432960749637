.searchStatus {
   opacity: 0.8;
   color: #222222;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   font-size: 14px;
   font-weight: 400;
   font-style: italic;
   letter-spacing: 0px;
   text-align: left;
}

.searchStatus__Spinner {
   padding-top: 12em;
}