.informationButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4em;
  height: 40px;
  border: 1px solid transparent;
  background-color: #dce3f6;
  color: #6389eb;
  font-size: 15px;
  font-weight: 700;
}

.informationButton:hover {
  /* background-color: #165ea7; */
  cursor: pointer;
}

.informationButton__Content {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
