.detailedForm__clear {
  margin-left: 10px;
  font-size: 10em;
}

.clearButton {
  color: #6389eb;
  font-size: 0.8rem;
  font-weight: 700;
  width: 86px;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.clearButton:hover {
  cursor: pointer;
  color: #809be0;
}
